
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        
















































































.lang-switcher {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: fit-content;
  margin-top: 2rem;
  margin-bottom: 0;
  padding-top: 0.5rem;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    display: block;
    height: 1px;
    background: $c-gray-medium;
  }

  @include mq($from: l) {
    position: absolute;
    z-index: 999;
    top: 0;
    right: 0;
    align-items: flex-end;
    margin-top: 0;
  }
}

.lang-switcher__title {
  margin-bottom: 0.5rem;
  color: $c-main-green;

  @include mq($from: l) {
    margin-bottom: 1.5rem;
  }
}

$custom-select-caret-size: 2.4rem;
$custom-select-caret-spacing: 0.9rem;

.custom-select {
  position: relative;
}

.custom-select__select {
  padding-right: $custom-select-caret-size + $custom-select-caret-spacing * 2;
  text-align: left;
  background-color: transparent;
  border-width: 0;
  -webkit-appearance: none;
  -moz-appearance: none;

  @include mq($from: l) {
    text-align: right;
  }
}

.custom-select__caret {
  position: absolute;
  pointer-events: none;
  top: 50%;
  right: $custom-select-caret-spacing;
  display: block;
  transform: translateY(-50%);
  width: $custom-select-caret-size;
  height: $custom-select-caret-size;
}
