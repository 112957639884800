
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        


















































































































.form-group {
  position: relative;
  width: 100%;
  margin-top: $spacing * 2;

  @include mq('s') {
    grid-column: 1 / 3;
  }
}

.form-labelarea {
  color: $c-black;
  font-size: 1.8rem;
  font-weight: 600;
}

.form-textarea {
  box-sizing: border-box;
  width: 100%;
  height: 260px;
  margin-top: 1.5rem;
  padding: 20px;
  resize: none;
  background-color: transparent;
  border: 1px solid $c-gray-medium;
  transition: border-color 0.2s;

  &:focus {
    border-color: $c-black;
  }
}

.form-labelarea__required {
  color: $c-dev-error;
}
