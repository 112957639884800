
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        





































































































































































































.hero {
  position: relative;
}

.hero__inner {
  position: relative;
}

.baseline {
  margin-bottom: 4.4rem;
  color: $c-gray-dark;
}

.hero__pictures-container {
  position: relative;
  height: vwify(816);
  margin-bottom: 9rem;
}

.hero__pictures-inner {
  position: absolute;
  overflow: hidden;

  &:nth-child(1) {
    top: vwify(71);
    left: vwify(-169);
    width: vwify(552);
    height: vwify(407);
  }

  &:nth-child(2) {
    top: vwify(187);
    left: vwify(476);
    width: vwify(721);
    height: vwify(482);
  }

  &:nth-child(3) {
    top: vwify(0);
    left: vwify(1290);
    width: vwify(467);
    height: vwify(373);
  }

  &:nth-child(4) {
    top: vwify(426);
    left: vwify(1437);
    width: vwify(282);
    height: vwify(390);
  }
}

.hero__picture {
  min-height: unset;
}

.location {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 5.3rem;

  svg {
    fill: $c-main-green;
  }
}

.location__icon {
  display: block;
  width: 2.4rem;
  height: 2.4rem;
  fill: $c-main-green;
  margin-right: 0.5rem;
}

.location__label {
  margin-left: 0.5rem;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 2rem;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  opacity: 0.4;
}
