
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        
































































































































































































.internal-navigation-container {
  position: sticky;
  z-index: 9;
  top: $header-height-mobile;
  text-align: center;
  background: $c-white;
  border-top: 1px $c-gray-light solid;
  border-bottom: 1px $c-gray-light solid;
  transition: top 0.3s ease-out;

  &.up {
    top: $header-height-mobile;
  }

  @include mq($from: $header-breakpoint) {
    top: 0;

    &.up {
      top: $header-height;
    }
  }
}

.internal-navigation {
  display: none;

  @include mq(l) {
    display: block;
  }
}

.internal-navigation-select {
  margin: 1rem auto;
  border-width: 0;

  @include mq(l) {
    display: none;
  }
}

.internal-navigation__list {
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.internal-navigation__list-item {
  flex-shrink: 0;
  margin: 0 5rem 0 0;
  padding: 0;

  &:last-of-type {
    margin: 0;
  }
}

.internal-navigation__anchor {
  position: relative;
  display: block;
  padding: 1rem 0;
  color: $c-black;
  font-size: 1.5rem;
  text-decoration: none;
  background-color: transparent;
  border-width: 0;
  cursor: pointer;

  &::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: -1px;
    left: 0;
    height: 2px;
    background: $c-main-green;
    transform: scaleY(0);
    transform-origin: 50% 100%;
    transition: all $transition-duration $ease-out;
  }

  &.active {
    &::after {
      transform: scaleY(1);
    }
  }
}
