
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        














































































































































































































::v-deep fieldset {
  display: flex;
  flex-wrap: wrap;
  margin: 0 $spacing * -0.5 3.2rem;
  padding: 0;
  border: 0;
}

::v-deep .form-group {
  margin: 0 $spacing * 0.5 $spacing * 2;

  @include mq(m) {
    margin: 0 $spacing * 1 5rem;
  }
}

.form-confirmation {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  @include mq(m) {
    flex-direction: row-reverse;
  }
}

.form-confirmation__button {
  @include mq(m) {
    order: -1;
    margin-left: $spacing * 2;
  }
}

.form-confirmation__feedback {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: $spacing;
  color: $c-gray-darker;

  .icon {
    margin-right: $spacing * 0.5;
    color: $c-main-green;
  }

  @include mq(m) {
    margin: 0 0 0 $spacing;
  }
}

.form-confirmation__feedback__dot {
  display: inline-block;
  width: 4px;
  height: 4px;
  background: $c-main-green;
  border-radius: 50%;
  animation: sending 1s infinite;

  & + & {
    margin-left: $spacing * 0.25;
    animation-delay: 0.1s;
  }

  &:last-child {
    animation-delay: 0.2s;
  }
}

@keyframes sending {
  25% {
    transform: translateY(-5px);
  }

  50% {
    transform: translateY(0);
  }
}

.recaptcha-outer {
  display: flex;
  justify-content: flex-end;
}

.recaptcha {
  margin-top: $spacing * 2;
  margin-left: auto;
}
